@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.loader-component {
    background-color: rgb(248, 248, 248, 60%);
    position: fixed;
    z-index: 1000000;
}

.dark-layout {
    .loader-component {
        background-color: rgb(22, 29, 49, 60%);
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}
.validation-error {
    margin-top: 0.3rem;
    font-size: 0.857rem;
    color: #ea5455;
}

@media (max-width: 768px) {
    .d-flex {
        flex-direction: row !important; /* Ensure layout is side-by-side */
    }

    .my-auto {
        align-self: center;
        width: 100%; /* Input field container takes full width */
    }

    .w-100 {
        width: 100% !important; /* Input field full width */
    }
}

.dropdown-button {
    &:hover,
    &:active,
    &:focus {
        background-color: #e3e3e3 !important;
        border-color: transparent;
        border: transparent;
    }
    border: transparent;
}

.form-label {
    font-size: 0.9rem;
    color: #0a0a0a;
}

.label-css {
    color: #eb5b5c;
    font-size: medium;
}

//server
.username-copy-btn {
    width: 40px !important;
    height: 39px !important;
    padding: 8px !important;
    border: 1px solid #ccc !important;
    border-radius: 8px !important;
    background-color: #fff !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.username-style {
    display: flex;
    align-items: center;
}
.user {
    margin-right: 7px;
    opacity: 1 !important;
}

//password
.password-copy-Btn {
    position: absolute;
    right: -3px;
    top: 70%;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.password {
    position: absolute;
    right: 57px;
    top: 70%;
    transform: translateY(-50%);
}
.password-class {
    display: flex;
    align-items: center;
}

.password-style {
    flex: 1;
    max-width: 450px;
    margin-right: 45px;
    opacity: 1 !important;
}

//SuperPassword Copy
.superPassword-copy-Btn {
    position: absolute;
    right: -3px;
    top: 70%;
    width: 40px;
    height: 39px;
    transform: translateY(-50%);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.superPassword {
    flex: 1;
    margin-right: 45px;
    opacity: 1 !important;
}

.superPassword-style {
    display: flex;
    align-items: center;
}

// super password eye icon
.super-password {
    position: absolute;
    right: 55px;
    top: 70%;
    transform: translateY(-50%);
}

.privatekey-copy-Btn {
    position: absolute;
    right: -12px;
    top: 25%;
    height: 40px;
    width: 40px;
    transform: translateY(-50%);
    padding: 11px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.privatekey-textarea {
    display: flex;
    align-items: center;
    padding: 4px;
    max-width: 98%;
    position: relative;
    padding: 3px;
}
.privatekey {
    margin-right: 30px !important;
    opacity: 1 !important;
}


.refresh-btn{
    border: none !important;
}

.refresh-btn:focus,
.refresh-btn:active {
    background-color: transparent !important;
}

.name-cardShimmer {
    height: 20px;
    width:100%;
}

@media (min-width: 1300px) and (max-width: 1499px) {
    .name-cardShimmer {
        // width: 250px !important;
        height: 16px;
    }
}

@media (min-width: 1100px) and (max-width: 1299px) {
    .name-cardShimmer {
        // width: 190px !important;
        height: 16px;
    }
}

@media (min-width: 978px) and (max-width: 1093px) {
    .name-cardShimmer {
        // width: 170px !important;
        height: 16px;
    }
}

@media (min-width: 700px) and (max-width: 799px) {
    .name-cardShimmer {
        // width: 320px !important;
        height: 16px;
    }
}
@media (min-width: 500px) and (max-width: 699px) {
    .name-cardShimmer {
        // width: 280px !important;
        height: 16px;
    }
}

// @media (min-width: 405px) and (max-width: 699px) {
//     .name-cardShimmer {
//         width: 150px !important;
//         height: 16px;
//     }
// }
.image-temp-card {
    // width:400px ;
    // height: 180px;
}

.image-cardShimmer {
    width: 393px !important;
    height: 150px;
    object-fit: cover;
}

@media (min-width: 1771px) and (max-width: 1870px) {
    .image-cardShimmer {
        width: 347px !important;
        // height: 155px;
        object-fit: cover;
    }
    .image-temp-card {
        // width: 380px !important;
    }
}
@media (min-width: 1659px) and (max-width: 1770px) {
    .image-cardShimmer {
        width: 307px !important;
        // height: 150px;
        object-fit: cover;
    }
    .image-temp-card {
        // width: 380px !important;
    }
}

@media (min-width: 1570px) and (max-width: 1658px) {
    .image-cardShimmer {
        width: 300px !important;
        // height: 155px;
        object-fit: cover;
    }
    .image-temp-card {
        // width: 380px !important;
    }
}

@media (min-width: 1500px) and (max-width: 1569px) {
    .image-cardShimmer {
        width: 250px !important;
        // height: 155px;
        object-fit: cover;
    }
    .image-temp-card {
        // width: 380px !important;
    }
}

@media (min-width: 1420px) and (max-width: 1499px) {
    .image-cardShimmer {
        width: 240px !important;
        // height: 155px;
        object-fit: cover;
    }
    .image-temp-card {
        // width: 380px !important;
    }
}

@media (min-width: 1300px) and (max-width: 1419px) {
    .image-cardShimmer {
        width: 210px !important;
        // height: 200px;
        object-fit: cover;
    }
    .image-temp-card {
        // width: 250px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .image-cardShimmer {
        width: 158px !important;
        // height: 180px;
        object-fit: cover;
    }
    .image-temp-card {
        // width: 190px !important;
    }
}

@media (min-width: 1100px) and (max-width: 1199px) {
    .image-cardShimmer {
        width: 177px !important;
        // height: 180px;
        object-fit: cover;
    }
    .image-temp-card {
        // width: 190px !important;
    }
}

@media (min-width: 1094px) and (max-width: 1099px) {
    .image-cardShimmer {
        width: 170px !important;
        // height: 180px;
        // object-fit: cover;
    }
    .image-temp-card {
        // width: 170px !important;
    }
}
@media (min-width: 992px) and (max-width: 1093px) {
    .image-cardShimmer {
        width: 170px !important;
        // height: 180px;
        // object-fit: cover;
    }
    .image-temp-card {
        // width: 170px !important;
    }
}
@media (min-width: 978px) and (max-width: 991px) {
    .image-cardShimmer {
        width: 380px !important;
        // height: 180px;
        // object-fit: cover;
    }
    .image-temp-card {
        // width: 170px !important;
    }
}
@media (min-width: 800px) and (max-width: 977px) {
    .image-cardShimmer {
        width: 323px !important;
        // height: 180px;
        // object-fit: cover;
    }
    .image-temp-card {
        // width: 170px !important;
    }
}

@media (min-width: 768px) and (max-width: 799px) {
    .image-cardShimmer {
        width: 298px !important;
        // height: 181px;
        // object-fit: cover;
    }
    .image-temp-card {
        // width: 320px !important;
    }
}

@media (min-width: 700px) and (max-width: 767px) {
    .image-cardShimmer {
        width: 585px !important;
        // height: 181px;
        // object-fit: cover;
    }
    .image-temp-card {
        // width: 320px !important;
    }
}

@media (min-width: 501px) and (max-width: 699px) {
    .image-cardShimmer {
        width: 379px !important;
        // height: 180px;
        object-fit: cover;
    }
    .image-temp-card {
        // width: 280px !important;
    }
}

.middle-shimmer{
    min-width: 650px !important;
    width: 100%;
}

@media (max-width: 502px) {
    .middle-shimmer{
        min-width: 150px !important;
    }
}
@media (max-width: 425px) {
    .middle-shimmer{
        min-width: 100px !important;
    }
}
@media (max-width: 1027px) {
    .middle-shimmer{
        min-width: 100px !important;
    }
}

.cards-parent{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 20px;
    column-gap: 15px;
}
.cards-child{
    border: 1px solid rgb(218, 225, 231);
    border-radius: 5px; 
}
.cards-child-1{
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
}
.email-section{
    display: flex;
    align-items: center;
    gap: 15px;
}
.cards-child-2{
    padding: 10px 15px 5px 15px;
}
.cards-child-3{
    padding: 2px 10px 10px 15px;
}
.cards-child-2 span{
    font-weight: bold;
    font-size: 14px;
}
.cards-child-1 h4{
    padding-top: 9px;
}
.border-class{
    border-right: 5px solid rgb(218, 225, 231) !important; 
}
@media (max-width:768px) {
    .border-class{
        border-right: none; 
    }
}
/* @media (max-width:1024px) {
    .cards-parent{
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 20px;
    }
}
@media (max-width:768px) {
    .cards-parent{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width:548px) {
    .cards-parent{
        grid-template-columns: 1fr;
    }
} */

@media (min-width: 471px) and (max-width: 500px) {
    .image-cardShimmer {
        width: 379px !important;
        // height: 180px;
        object-fit: cover;
    }
    .image-temp-card {
        // width: 280px !important;
    }
}


@media (max-width: 470px) {
    .image-cardShimmer {
        width: 245px !important;
        // height: 155px;
        object-fit: cover;
    }
}



/* Form SMTP */

.list-group-one{
    border: 1px dashed rgb(133, 133, 133);
    display: grid;
    border-radius: 3px;
    grid-template-columns: 1fr ;
    column-gap: 25px;
    row-gap: 2px;
    padding: 7px 6px;
    margin-top: 10px;
    margin-bottom: 15px;
}
.list-group-items{
    margin: 5px;
    padding: 8px 11px;
    /* border-radius: 5px; */
    background-color: #efebfc;
}
/* .close-all-icon{
   
} */    
.close-all-icon-div{
    position: absolute;
    top: -16px;
    right: -11px;
    background-color: white;
    /* border: 1px solid red; */
    border-radius: 5px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.border-right {
    border-right: 1px solid rgb(218, 225, 231);
  }
  
  @media (max-width: 767px) {
    .border-right {
      border-right: none;
      border-bottom: 1px solid rgb(218, 225, 231);
    }
  }


  .custom-profile-image {
    width: 28px;
    height: 28px;
    border-radius: 5px;
    margin-right: 10px;
    object-fit: cover;
    cursor: pointer;
}

.icon-color {
    color: #5e50ee;
}
.icon {
    display: flex;
    align-items: center;
}

.no-data-image {
    width: 360px;
    height: auto;
    opacity: 0.6;
}

@media (max-width: 476px) {
    .no-data-image {
        width: 300px;
    }
}
@media (min-width: 477px) and (max-width: 556px) {
    .no-data-image {
        width: 300px;
    }
}
@media (min-width: 557px) and (max-width: 575px) {
    .no-data-image {
        width: 300px;
    }
}
@media (min-width: 576px) and (max-width: 991px) {
    .no-data-image {
        width: 300px;
    }
}
@media (min-width: 992px) and (max-width: 1224px) {
    .no-data-image {
        width: 300px;
    }
}
@media (min-width: 1225px) and (max-width: 1400px) {
    .no-data-image {
        width: 300px;
    }
}
@media (min-width: 1401px) and (max-width: 1700px) {
    .no-data-image {
        width: 375px;
    }
}

.more-vertical-btn {
    padding: 0 !important;
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    .more-vertical-btn-icon-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        width: 38px;
        border-radius: 50%;
    }
}

.header-label {
    // font-size: 21px !important;
    font-weight: 700 !important;
    color: #1d1d1d;
}